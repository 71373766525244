.marquee {
  position: absolute;
  height: 12px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: transparent;
  display: flex;
  align-items: center;
  z-index: 1;
  border-radius: 4px;
}

.marquee span {
  position: absolute;
  padding: 0;
  margin: 0;
  display: inline-block;
  animation: marquee 10s linear infinite;
  font-size: 5px;
  text-transform: uppercase;
  font-weight: 700;

  &.pan-left {
    animation: marquee 10s linear infinite reverse;
  }

  &.pan-right {
    animation: marquee 10s linear infinite;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(20%);
  }
  100% {
    transform: translateX(-100%);
  }
}
