.draggable-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  cursor: grab; /* Default cursor */
  touch-action: none;

  &.grabbing {
    /* When dragging */
    cursor: grabbing;
  }
}
