@import url("https://unpkg.com/modern-css-reset@1.4.0/dist/reset.min.css");

body {
  background: #111;
  overscroll-behavior: none;
}

// #grid contains a series of image slices which compose a larger image
// each slice is 300px x 300px (at 0.5x source image resolution)
// the grid is inside of a viewport container with click-and-drag panning

#grid {
  display: grid;
  grid-template-columns: repeat(11, 300px);
  grid-template-rows: repeat(11, 300px);
  max-height: 100vh;
  max-width: 100vw;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    pointer-events: none;
  }

  .cell {
    position: relative;
    overflow: hidden;
    user-select: none;
  }
}

.logotype {
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
}
