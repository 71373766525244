#branding {
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    width: 40px;
    height: 15px;
    margin-right: 6px;
  }
}

#scrim {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 3;
}

#progressBarContainer {
  border: 1px solid #0000002a;
  overflow: hidden;
  height: 5px;
  width: 100px;
  margin: 0;
  padding: 0;
  display: flex;
  border-radius: 4px;

  svg {
    height: 5px;
    width: 100px;
  }
}

.alert-container {
  padding: 12px;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0.12) 100%,
    rgba(255, 255, 255, 0) 0%
  );

  .alert-message {
    padding: 12px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    width: calc(80vw - 20px);
    border: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    @media (min-width: 768px) {
      flex-direction: row;
      max-width: calc(50vw - 20px);
    }

    h1 {
      font-size: 1.2rem;
      margin: 0;
      color: rgba(0, 0, 0, 0.8);
    }

    p {
      font-size: 0.8rem;
      font-style: italic;
      color: rgba(0, 0, 0, 0.8);
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      padding: 5px 10px;
      margin: 0;
      cursor: pointer;
      background-color: #000;
      border: 0;
      border-radius: 4px;
      color: rgba(255, 255, 255, 0.8);
      font-size: 0.85rem;
      font-weight: 700;
      text-transform: uppercase;
      gap: 6px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.8);
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}
