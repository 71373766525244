nav {
  padding: 0;
  margin: 0;
  display: flex;
  position: fixed;
  top: 0;
  width: 100vw;
  left: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.875);
  flex-direction: row;
  justify-content: space-between;

  #logo {
    user-select: none;
    pointer-events: none;
    margin: 12px;
    padding: 0;
    display: flex;
    height: 18px;
  }

  #controls {
    display: flex;
    justify-content: center;
    align-self: center;
    padding: 6px;
  }

  #d-pad {
    position: relative;
    height: 34px;
    width: 34px;
    overflow: hidden;

    .direction {
      height: 14px;
      width: 14px;

      &:hover {
        opacity: 0.5;
        cursor: pointer;
      }
    }

    #d-up {
      background: url("../../../public/assets/icons/d-up.svg");
      position: absolute;
      top: 0;
      left: 10px;
    }

    #d-down {
      background: url("../../../public/assets/icons/d-down.svg");
      position: absolute;
      top: 20px;
      left: 10px;
    }

    #d-left {
      background: url("../../../public/assets/icons/d-left.svg");
      position: absolute;
      top: 10px;
      left: 0;
    }

    #d-right {
      background: url("../../../public/assets/icons/d-right.svg");
      position: absolute;
      top: 10px;
      left: 20px;
    }
  }
}
